export default {
  // Global

  // Neutral, light mode
  neutral000: "var(--t-fill-color-neutral-000)",
  neutral010: "var(--t-fill-color-neutral-010)",
  neutral020: "var(--t-fill-color-neutral-020)",
  neutral030: "var(--t-fill-color-neutral-030)",
  neutral040: "var(--t-fill-color-neutral-040)",
  neutral050: "var(--t-fill-color-neutral-050)",
  neutral060: "var(--t-fill-color-neutral-060)",
  neutral070: "var(--t-fill-color-neutral-070)",
  neutral080: "var(--t-fill-color-neutral-080)",
  neutral090: "var(--t-fill-color-neutral-090)",
  neutral100: "var(--t-fill-color-neutral-100)",

  neutral7: "var(--color-neutral-7)",
  neutral15: "var(--color-neutral-15)",
  neutral17: "var(--color-neutral-17)",
  neutral19: "var(--color-neutral-19)",
  neutral32: "var(--color-neutral-32)",
  neutral50: "var(--color-neutral-50)",
  neutral68: "var(--color-neutral-68)",

  // Interaction
  interactionDefault: "var(--t-fill-color-interaction-default)",
  interactionHover: "var(--t-fill-color-interaction-hover)",
  interactionActive: "var(--t-fill-color-interaction-active)",
  interactionDisabled: "var(--t-fill-color-interaction-disabled)",

  // Alerts
  alertSuccess: "var(--t-fill-color-alert-success)",
  alertWarning: "var(--t-fill-color-alert-warning)",
  alertError: "var(--t-fill-color-alert-error)",
  alertInfo: "var(--t-fill-color-alert-info)",

  alertSubtleBg: "var(--t-fill-color-status-info-ghost)",

  // Status
  statusCreateControls: "var(--color-status-create-controls)",

  statusWarningPrimary: "var(--t-icon-color-status-warning-primary)",
  statusWarningSolid: "var(--t-fill-color-status-warning-solid)",
  statusWarningGhost: "var(--t-fill-color-status-warning-ghost)",
  statusWarningText: "var(--t-text-color-status-warning)",

  statusErrorSolid: "var(--t-fill-color-status-error-solid)",
  statusErrorText: "var(--t-text-color-status-error)",

  // Tags
  tagAquaBg: "var(--t-fill-color-tag-subtle-aqua)",
  tagAquaIcon: "var(--t-fill-color-alert-info)",
  tagAquaText: "var(--t-text-color-tag-aqua)",

  tagBlueBg: "var(--t-fill-color-tag-subtle-blue)",
  tagBlueIcon: "var(--t-fill-color-interaction-default)",
  tagBlueText: "var(--t-text-color-tag-blue)",

  tagGrayBg: "var(--t-fill-color-tag-subtle-gray)",
  tagGrayIcon: "var(--t-icon-color-default-dim)",
  tagGrayText: "var(--t-text-color-tag-gray)",

  tagGreenBg: "var(--t-fill-color-tag-subtle-green)",
  tagGreenIcon: "var(--color-tag-green-icon)",
  tagGreenText: "var(--t-text-color-tag-green)",

  tagOrangeBg: "var(--t-fill-color-tag-subtle-orange)",
  tagOrangeIcon: "var(--t-fill-color-product-groups-base)",
  tagOrangeText: "var(--t-text-color-tag-orange)",

  tagRedBg: "var(--t-fill-color-status-error-ghost)",
  tagRedIcon: "var(--t-fill-color-status-error-solid)",
  tagRedText: "var(--color-tag-red-text)",

  tagYellow: "var(--t-fill-color-tag-subtle-yellow)",
  tagYellowIcon: "var(--t-fill-color-tag-bold-yellow)",
  tagYellowText: "var(--t-text-color-tag-yellow)",

  // Products - People
  productPeopleDarkest: "var(--t-fill-color-product-people-darkest)",
  productPeopleDarker: "var(--t-fill-color-product-people-darker)",
  productPeopleDark: "var(--t-fill-color-product-people-dark)",
  productPeopleLightest: "var(--t-fill-color-product-people-lightest)",
  productPeopleLighter: "var(--t-fill-color-product-people-lighter)",
  productPeopleLight: "var(--t-fill-color-product-people-light)",
  productPeopleBase: "var(--t-fill-color-product-people-base)",

  // Products - All
  productAccountsBase: "var(--t-fill-color-product-accounts-base)",
  productCalendarBase: "var(--t-fill-color-product-calendar-base)",
  productCheckInsBase: "var(--t-fill-color-product-checkins-base)",
  productGivingBase: "var(--t-fill-color-product-giving-base)",
  productGroupsBase: "var(--t-fill-color-product-groups-base)",
  productHomeBase: "var(--t-fill-color-product-home-base)",
  productPublishingBase: "var(--t-fill-color-product-publishing-base)",
  productRegistrationsBase: "var(--t-fill-color-product-registrations-base)",
  productServicesBase: "var(--t-fill-color-product-services-base)",

  // People specific

  // Basic colors
  accentColor: "var(--accent-color)",
  base0: "var(--base0)",
  base1: "var(--base1)",
  base2: "var(--base2)",
  base3: "var(--base3)",
  baseColor: "var(--base-color)",
  bgColor: "var(--bgColor)",
  borderColor: "var(--border-color)",
  dangerColor: "var(--danger-color)",
  primaryBtnColor: "var(--primary-btn-color)",
  primaryBtnFocusColor: "var(--primary-btn-focus-color)",
  sidebarBgColor: "var(--sidebar-bg-color)",
  successColor: "var(--success-color)",
  warningColor: "var(--warning-color)",
  warningOrange: "var(--warning-orange)",
  warningOrangeLight: "var(--warning-orange-light)",
  warningTextColor: "var(--warning-text-color)",

  // Component aliases
  blankStateHeadingColor: "var(--blank-state-heading-color)",

  // Tapestry-React-specific aliases
  error: "var(--t-fill-color-status-error-solid)",
  errorDark: "var(--t-fill-color-button-delete-solid-hover)",
  errorDarker: "var(--t-fill-color-button-delete-solid-active)",
  errorLighter: "var(--t-fill-color-button-delete-ghost-active)",
  errorLightest: "var(--t-fill-color-button-delete-ghost-hover)",
  highlight: "var(--t-fill-color-button-default-info-ghost-hover)",
  linkBackground: "var(--bgColor)",
  primary: "var(--t-fill-color-product-people-base)",
  primaryDark: "var(--t-fill-color-product-people-dark)",
  primaryDarker: "var(--t-fill-color-product-people-darker)",
  primaryDarkest: "var(--t-fill-color-product-people-darkest)",
  primaryLight: "var(--t-fill-color-product-people-light)",
  primaryLighter: "var(--t-fill-color-product-people-lightest)",
  primaryLightest: "var(--OUT-OF-SCOPE-color-people-brand-91)",
  separatorFocus: "var(--base-color)",
  separatorFocusSecondary: "var(--blue-mesa)",
  warning: "var(--warning-orange)",
  warningLight: "var(--warning-orange-light)",
  warningLighter: "var(--warning-orange-light)",

  // Blues palette
  blueMesa: "var(--blue-mesa)",
  blueSkies: "var(--blue-skies)",
  raindrop: "var(--raindrop)",
  glacier: "var(--glacier)",
  ice: "var(--ice)",
  snow: "var(--snow)",
  whisper: "var(--whisper)",

  // Blue-grays palette
  royal: "var(--royal)",
  regentGray: "var(--regent-gray)",
  shipCove: "var(--ship-cove)",
  nepal: "var(--nepal)",
  rockBlue: "var(--rock-blue)",
  cadetBlue: "var(--cadet-blue)",
  ghost: "var(--ghost)",
  waterTemple: "var(--water-temple)",
  spartaGray: "var(--sparta-gray)",
}
